import {media} from '@nfq/react-grid';
import styled from 'styled-components';

import {GridItem} from 'UI/components/layout/GridItem';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * The `Details` Component.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier, usually in the form of a string, assigned to the component for testing purposes.
 * @returns A React element representing the `Details` component.
 *
 * @example
 * ```tsx
 * const MyComponent = <Details testId="myTestId">MyComponent</Details>;
 * ```
 */
const Details = ({testId}: ComponentProps) => (
    <InViewAnimContainer baseComponent={Wrapper} data-cy={testId} stagger={0.1}>
        <GridItem label="Abmessungen" testId="abmessungen-item">
            Höhe: 191 mm<br />
            Breite: 160 mm<br />
            Tiefe: 85 mm
        </GridItem>
        <GridItem label="Audioquelle" testId="audioquelle-item">
            Unterstütztes Bluetooth Gerät, beispielsweise ein Smartphone.
        </GridItem>
        <GridItem label="LED" testId="led-item">
            Leuchten auf der Unterseite der linken Hörmuschel zeigen den Verbindungsstatus an.
        </GridItem>
        <GridItem label="Oberfläche" testId="oberfläche-item">
            Matt
        </GridItem>
        <GridItem label="Gewicht" testId="gewicht-item">
            312 g
        </GridItem>
        <GridItem label="Farben" testId="farben-item">
            Schwarz, Soft Weiß
        </GridItem>
        <GridItem label="Stromversorgung" testId="stromversorgung-item">
            Aufladen mit einem USB-C-Netzteil und einer Steckdose in der Nähe.
        </GridItem>
    </InViewAnimContainer>
);

Details.hash = '#details';
Details.displayName = 'Details';
Details.defaultProps = {testId: 'Details'};

export {Details};

const Wrapper = styled.div`
    display: grid;
    grid-gap: 5.6rem;
    grid-template-columns: 1fr;
    width: 100%;

    ${media('sm')} {
        grid-template-columns: 1fr 1fr;
    }

    ${media('lg')} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;