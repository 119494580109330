/* eslint-disable max-len */
import React from 'react';

interface MikrofonProps {
    /**
     * A string representing the CSS class to be applied to the MikrofonIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the MikrofonIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the MikrofonIcon element.
     */
    width?: number | string;
}

/**
 * The `Mikrofon` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `MikrofonProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the MikrofonIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the MikrofonIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the MikrofonIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const MikrofonComponent = <Mikrofon className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Mikrofon = React.forwardRef<SVGSVGElement, MikrofonProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 80 80"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path d="m60.66 45.05-.28 1.2c-2.07 9.1-10.9 15.96-20.57 15.96s-18.5-6.86-20.57-15.96l-.27-1.2H11l.34 1.82C13.5 58.7 23.7 68.2 35.83 69.92v2.1h-.91a4 4 0 0 0 0 7.99h9.8a4 4 0 0 0 0-7.99h-.9v-2.1c12.13-1.71 22.31-11.2 24.49-23.05l.33-1.82z" fill={color1} />
                <path clipRule="evenodd" d="M39.82 54.51c7.64 0 13.82-7.12 13.82-15.9V15.9c0-8.78-6.2-15.9-13.82-15.9S26.01 7.12 26.01 15.9v22.7c0 8.78 6.19 15.9 13.81 15.9" fill={color1} fillRule="evenodd" />
            </g>
        </svg>
    );
});

Mikrofon.displayName = 'Mikrofon';
Mikrofon.defaultProps = {
    className: '',
    color1: '#212325',
    height: 80,
    testId: 'Mikrofon',
    width: 80
};