import lgMp4 from './listeningPerfected_lg.mp4';
import lgWebm from './listeningPerfected_lg.webm';
import xsMp4 from './listeningPerfected_xs.mp4';
import xsWebm from './listeningPerfected_xs.webm';

export const listeningPerfected = {
    lg: {
        mp4: lgMp4,
        webm: lgWebm
    },
    xs: {
        mp4: xsMp4,
        webm: xsWebm
    }
};