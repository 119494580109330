import {useState} from 'react';

/**
 * A custom React hook that provides functionality to toggle the open/close state of an accordion component.
 * This hook initializes the accordion's state to closed (`false`). It exposes a `handleClick` function that toggles
 * the `isOpen` state, effectively opening or closing the accordion. This hook is ideal for controlling the visibility
 * of the accordion content and can be used in conjunction with any component that requires open/close functionality.
 *
 * @returns An object containing the `isOpen` state to indicate whether the accordion is open or closed, and the `handleClick`
 * function to toggle this state. This allows components using this hook to easily implement open/close functionality with
 * a single function call.
 *
 * @example
 * ```ts
 * const {handleClick, isOpen} = useAccordion();
 * ```
 */
export const useAccordion = () => {
    const [isOpen, setIsOpen] = useState(false);

    /**
     * Toggles the current state of the accordion from open to closed, or vice versa.
     * This function is a crucial part of the accordion's functionality, allowing the user to control
     * the visibility of the accordion's content. It works by inversing the current boolean value of `isOpen`.
     * This straightforward approach ensures that the accordion's state is easily toggled with a single function call,
     * providing a seamless user experience. This function is typically used in conjunction with a custom hook that
     * manages the accordion's state, such as `useAccordion`.
     *
     * @returns Nothing. The sole purpose of this function is to toggle the internal state managed by a useState hook.
     *
     * @example
     * ```ts
     * <YourComponent onClick={handleClick}>Toggle Accordion</YourComponent>
     * ```
     */
    const handleClick = () => setIsOpen(!isOpen);

    return {
        handleClick,
        isOpen
    };
};