import {media, mediaBetween, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Color, H1} from 'UI/components/layout/Text';
import {Video} from 'UI/components/layout/Video';

import {listeningPerfected} from 'Videos/listeningPerfected';

import {useFullsizeVideo} from './useFullsizeVideo';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a full-size video component with dynamic scaling and translation effects based on the user's scroll position.
 * This component utilizes the `useFullsizeVideo` hook to control video playback and apply real-time visual effects,
 * enhancing user interaction and engagement. The video automatically plays when entering the viewport and pauses upon
 * leaving, creating an immersive experience. Text content is animated alongside the video, moving horizontally to further
 * captivate the viewer. The component is designed to be responsive, with video source options and scaling factors that adjust
 * based on the viewing environment. This component is ideal for showcasing products, services, or any content where visual impact
 * is paramount.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that displays a full-size, responsive video with accompanying animated text, controlled by scroll position.
 *
 * @example
 * ```tsx
 * <FullsizeVideo testId="fullsize-video-component" />
 * ```
 */
const FullsizeVideo = ({testId}: ComponentProps) => {
    const {
        colors,
        pauseVideo,
        playVideo,
        scale,
        scrollRef,
        textRef,
        translateX,
        videoRef
    } = useFullsizeVideo();
    const VideoMotion = motion(StyledVideo);

    return (
        <VideoWrapper ref={scrollRef} data-cy={testId}>
            <VideoMotion
                ref={videoRef}
                src={listeningPerfected}
                style={{scale: scale as unknown as string}}
                width="100%"
                loop
                muted
                playsInline
                onViewportEnter={playVideo}
                onViewportLeave={pauseVideo}
            />
            <TextWrapper style={{translateX}}>
                <H1 ref={textRef} as="p"><Color $color={colors.primaryFontColorLight}>Personal</Color></H1>
                <H1 as="p"><Color $color={colors.primaryFontColorLight}>Listening</Color></H1>
                <H1 as="p"><Color $color={colors.primaryFontColorLight}>Perfected</Color></H1>
            </TextWrapper>
        </VideoWrapper>
    );
};

FullsizeVideo.displayName = 'FullsizeVideo';
FullsizeVideo.defaultProps = {testId: 'FullsizeVideo'};

export {FullsizeVideo};

const VideoWrapper = styled(motion.div)`
    line-height: 0;
    overflow: hidden;
    position: relative;
`;

const StyledVideo = styled(Video)`
    aspect-ratio: 390 / 667;
    object-fit: cover;
    object-position: center;
    width: 100%;

    ${mediaBetween('xs', 'md')} {
        transform: none !important;
    }

    ${media('md')} {
        aspect-ratio: initial;
        height: auto;
        width: 100%;
    }
`;

const TextWrapper = styled(motion.div)`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    margin: auto;
    min-width: 100%;
    padding: ${spacing(29)} 0;
    position: absolute;
    right: 0;
    top: 0;

    ${mediaBetween('xs', 'md')} {
        transform: none !important;
    }

    ${media('md')} {
        flex-direction: row;
        gap: ${spacing(60)};
        justify-content: center;
        padding: 0 ${spacing(20)};
    }
`;