import {useRef} from 'react';

import {useScroll, useTransform} from 'framer-motion';

import {useThemeColors} from 'UI/hooks/useThemeColors';
import {useVideoPlay} from 'UI/hooks/useVideoPlay';

/**
 * A custom React hook designed to facilitate the creation of full-size video components with dynamic visual effects
 * based on scroll position. It integrates video playback controls and scroll-based animations for both video scaling
 * and text translation. This hook separates the scroll progress for video and text, allowing for independent animation
 * controls. It provides a powerful interface for creating engaging and interactive sections on a webpage, where the video
 * responds to viewport presence and text moves based on scroll interactions. Additionally, it manages references for both
 * the video and text elements to apply scroll effects and controls, making it a versatile tool for building immersive
 * web experiences.
 *
 * @returns An object containing several pieces of state and functions useful for controlling a full-size video component:
 * - `colors`: Theme colors for consistent styling across the component.
 * - `pauseVideo` and `playVideo`: Functions to control the video playback.
 * - `scale`: A motion value for the video scaling effect based on the user's scroll progress.
 * - `scrollRef` and `textRef`: Ref objects for attaching to the video and text elements respectively, to monitor their scroll positions.
 * - `translateX`: A motion value for the text translation effect, providing a parallax-like experience as the user scrolls.
 * - `videoRef`: A ref object for the video element, enabling direct manipulation and playback control.
 *
 * @example
 * ```ts
 * const {
 *   colors,
 *   pauseVideo,
 *   playVideo,
 *   scale,
 *   scrollRef,
 *   textRef,
 *   translateX,
 *   videoRef,
 * } = useFullsizeVideo();
 * ```
 */
export const useFullsizeVideo = () => {
    const scrollRef = useRef(null);
    const textRef = useRef(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const colors = useThemeColors();
    const {scrollYProgress: scrollYProgressVideo} = useScroll({
        offset: ['start end', '0.8 start'],
        smooth: 20,
        target: scrollRef
    });
    const {scrollYProgress: scrollYProgressText} = useScroll({
        offset: ['start end', '0.8 start'],
        smooth: 20,
        target: textRef
    });

    const {pauseVideo, playVideo} = useVideoPlay(videoRef);

    const scale = useTransform(scrollYProgressVideo, [0, 0.4], [0.3, 1]);
    const translateX = useTransform(scrollYProgressText, [0, 0.5, 1], ['-75%', '0%', '75%']);

    return {
        colors,
        pauseVideo,
        playVideo,
        scale,
        scrollRef,
        textRef,
        translateX,
        videoRef
    };
};