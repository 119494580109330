import type {Variants} from 'framer-motion';

export const AccordionHeaderTransition: Variants = {
    close: {},
    open: {}
};

export const PlusMinusTransition: Variants = {
    close: {
        opacity: 1,
        transition: {duration: 0.15}
    },
    open: {
        opacity: 0,
        transition: {duration: 0.15}
    }
};

export const AccordionContentTransition: Variants = {
    close: {
        height: '0px',
        opacity: 0,
        transition: {duration: 0.5}
    },
    open: {
        height: 'auto',
        opacity: 1,
        transition: {duration: 0.5}
    }
};

export const PlusAnimation: Variants = {
    close: {
        rotate: 0,
        transition: {
            duration: 0.3,
            ease: 'easeInOut'
        }
    },
    open: {
        rotate: -180,
        transition: {
            duration: 0.3,
            ease: 'easeInOut'
        }
    }
};