import type {Variants} from 'framer-motion';

export const textWrapperTransition: Variants = {
    initial: {},
    /**
     * The `enter` variant is used to animate the component when it is being displayed.
     *
     * @param isMobile A boolean value that indicates whether the current screen size is mobile or not.
     * @returns An object containing the styles to be applied to the component when it is being displayed.
     */
    enter(isMobile: boolean) {
        return isMobile ? {} : {
            minWidth: '20%',
            opacity: 1,
            transform: 'translateY(0px)',
            transition: {
                delay: 1,
                duration: 1,
                opacity: {
                    delay: 2,
                    duration: 0.5,
                    ease: 'easeInOut'
                },
                transform: {
                    delay: 2,
                    duration: 0.5,
                    ease: 'easeInOut'
                }
            },
            width: '400px'
        };
    }
};

export const headerWrapperTransition: Variants = {

    /**
     * The `enter` variant is used to animate the component when it is being displayed.
     *
     * @param isMobile A boolean value that indicates whether the current screen size is mobile or not.
     * @returns An object containing the styles to be applied to the component when it is being displayed.
     */
    enter(isMobile: boolean) {
        return isMobile ? {} : {
            transition: {
                delay: 1,
                duration: 1
            },
            width: '390px'
        };
    },

    /**
     * The `initial` variant is used to set the initial styles of the component.
     *
     * @param isMobile A boolean value that indicates whether the current screen size is mobile or not.
     * @returns An object containing the styles to be applied to the component when it is being displayed.
     */
    initial(isMobile: boolean) {
        return isMobile ? {} : {
            transition: {
                delay: 1,
                duration: 1
            },
            width: '650px'
        };
    }
};