import type {RefObject} from 'react';

/**
 * Provides a simplified interface for controlling video playback, encapsulating the functionality within a custom React hook.
 * This hook accepts a reference to a video element and returns two methods: `playVideo` and `pauseVideo`, which abstract away
 * the logic required to play and pause the video, respectively. By utilizing this hook, React components can easily control
 * video playback without directly managing asynchronous operations or checking for the existence of the video element. This
 * approach enhances code readability and maintainability, particularly in complex components where video playback is one of
 * many interactions.
 *
 * @param videoRef A React ref object pointing to the video element to be controlled. This allows the hook to directly interact with the video DOM element, playing or pausing the video as needed.
 * @returns An object containing two asynchronous functions, `playVideo` and `pauseVideo`, which when called, play or pause the video referenced by `videoRef`, respectively.
 *
 * @example
 * ```ts
 * const { playVideo, pauseVideo } = useVideoPlay(videoRef);
 *
 * // To play the video
 * playVideo();
 *
 * // To pause the video
 * pauseVideo();
 * ```
 */
export const useVideoPlay = (videoRef: RefObject<HTMLVideoElement>) => {
    /**
     * A function designed to control the playback state of a video element based on a boolean parameter. This function
     * checks if the video element is currently referenced (`videoRef.current` is not null) and then either plays or pauses
     * the video based on the value of the `play` parameter. When `play` is true, it attempts to play the video, and if `play`
     * is false, it pauses the video. The function handles asynchronous operations, waiting for the video to begin playing before
     * proceeding, which is particularly useful for handling videos with autoplay restrictions.
     *
     * @param play A boolean value determining the playback state of the video. If true, the video attempts to play; if false, the video is paused.
     *
     * @example
     * ```ts
     * // To play the video
     * toggleVideoPlay(true);
     *
     * // To pause the video
     * toggleVideoPlay(false);
     * ```
     */
    const toggleVideoPlay = (play: boolean) => {
        if (videoRef.current) {
            if (play) {
                // eslint-disable-next-line promise/prefer-await-to-callbacks, promise/prefer-await-to-then
                void videoRef.current.play().catch(() => {});
            } else {
                videoRef.current.pause();
            }
        }
    };

    return {
        pauseVideo: () => toggleVideoPlay(false),
        playVideo: () => toggleVideoPlay(true)
    };
};