import {Container, media, Spacer, spacing, Visible} from '@nfq/react-grid';
import styled from 'styled-components';

import {Accordion} from 'UI/components/action/Accordion/Accordion';
import {Tabs} from 'UI/components/action/Tabs';
import {Audio} from 'UI/components/layout/tabContents/Audio';
import {Controls} from 'UI/components/layout/tabContents/Controls';
import {Delivery} from 'UI/components/layout/tabContents/Delivery';
import {Details} from 'UI/components/layout/tabContents/Details';
import {Energy} from 'UI/components/layout/tabContents/Energy';
import {H3} from 'UI/components/layout/Text';

import {getTabHeadItems} from './utils';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders the Technical Details section of a product or feature page, providing tabs and accordions for various categories like Audio, Details, Controls, etc.
 * This component dynamically adjusts its layout based on screen size: it uses tabs for larger screens and accordions for smaller screens.
 * The `TechnicalDetails` component serves as a flexible container that allows users to explore different aspects of a product through organized interfaces.
 *
 * @param props        The component props.
 * @param props.testId The test identifier used for selecting this component in automated tests.
 * @returns A JSX.Element that includes headings, spacers, and conditional rendering of Tabs or Accordions based on screen size.
 *
 * @example
 * ```tsx
 * <TechnicalDetails testId="tech-details">
 *   // Additional JSX or components to render specific technical details
 * </TechnicalDetails>
 * ```
 */
const TechnicalDetails = ({testId}: ComponentProps) => (
    <StyledContainer data-cy={testId} id="technical-details">
        <H3 as="h2">Technische Daten</H3>
        <Spacer y={{md: 16, xs: 0}} />
        <Visible lg md xl xxl>
            <Tabs
                activeTab="#audio"
                headItems={getTabHeadItems()}
            >
                <Audio testId="audio" />
                <Details testId="details" />
                <Controls testId="controls" />
                <Energy testId="energy" />
                <Delivery testId="delivery" />
            </Tabs>
        </Visible>
        <Visible sm xs>
            <>
                <Accordion title="Audio">
                    <Audio testId="audio" />
                </Accordion>
                <Accordion title="Details">
                    <Details testId="details" />
                </Accordion>
                <Accordion title="Controls">
                    <Controls testId="controls" />
                </Accordion>
                <Accordion title="Energy">
                    <Energy testId="energy" />
                </Accordion>
                <Accordion title="Delivery">
                    <Delivery testId="delivery" />
                </Accordion>
            </>
        </Visible>
    </StyledContainer>
);

TechnicalDetails.displayName = 'TechnicalDetails';
TechnicalDetails.defaultProps = {testId: 'TechnicalDetails'};

export {TechnicalDetails};

interface StyledContainerProps {
    id: string;
}

const StyledContainer = styled(Container)<StyledContainerProps>`
    padding-block: ${spacing(14)};

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;