import type {ReactNode} from 'react';

import {media, Spacer, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Color, P} from 'UI/components/layout/Text';

import {useTabs} from './useTabs';

import type {TabComponent} from 'types/global';

export interface TabHeadItems {
    hash: string;
    label: ReactNode;
}

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The identifier of the initially active tab.
     */
    activeTab: string;
    /**
     * An array of `TabComponent` elements, each representing a section of content.
     * The content of each tab is rendered based on the active tab state.
     */
    children: TabComponent[];
    /**
     * An array of `TabHeadItems`, which are used to render the clickable tab headers.
     * Each item corresponds to a tab and includes properties like the label and identifier for the tab.
     */
    headItems: TabHeadItems[];
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Constructs a tabbed interface allowing users to switch between different views or sections within the component.
 * The `Tabs` component manages user interactions through clickable tab headers, which switch the displayed content based on the user's selection.
 * It utilizes a custom hook `useTabs` to manage state such as the active tab and associated content rendering, along with handling tab change events.
 * This component is ideal for organizing content that needs to be separated into distinct sections without leaving the page, providing a clean and interactive user experience.
 *
 * @param props           The component props.
 * @param props.activeTab The identifier of the initially active tab.
 * @param props.children  The content of each tab that will be conditionally rendered based on the active tab.
 * @param props.headItems An array of items representing each tab head, each item containing a label and a unique hash used as a key.
 * @param props.testId    A unique identifier for testing purposes.
 * @returns A JSX element representing a fully interactive tab component with configurable content and style.
 *
 * @example
 * ```tsx
 * <Tabs
 *   activeTab="tab1"
 *   headItems={[{ hash: 'tab1', label: 'Tab 1' }, { hash: 'tab2', label: 'Tab 2' }]}
 *   testId="tabs-example"
 * >
 *   <div>Content for Tab 1</div>
 *   <div>Content for Tab 2</div>
 * </Tabs>
 * ```
 */
const Tabs = ({activeTab, children, headItems, testId}: ComponentProps) => {
    const {activeChild, colors, handleTab, tab} = useTabs(activeTab, children);

    return (
        <Wrapper data-cy={testId}>
            <Head>
                {headItems.map(item => (
                    <Tab key={item.hash} onClick={() => handleTab(item.hash)}>
                        <StyledP>
                            <Color $color={item.hash === tab ? colors.primaryFontColor : colors.secondaryFontColor}>
                                {item.label}
                            </Color>
                        </StyledP>
                        {item.hash !== tab && <DummyIndicator />}
                        {item.hash === tab && <Indicator layoutId="Indicator" layout />}
                    </Tab>
                ))}
            </Head>
            <Spacer y={16} />
            <Body>
                {activeChild}
            </Body>
        </Wrapper>
    );
};

Tabs.displayName = 'Tabs';
Tabs.defaultProps = {testId: 'Tabs'};

export {Tabs};

const Wrapper = styled.div``;

const Head = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing(8)};
    justify-content: flex-start;
    width: 100%;

    ${media('lg')} {
        gap: ${spacing(16)};
    }
`;

const Tab = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    width: max-content;
`;

const StyledP = styled(P)`
    display: inline-block;
    min-width: 0;
    white-space: nowrap;
`;

const Indicator = styled(motion.div)`
    background-color: ${({theme}) => theme.colors.primaryFontColor};
    height: 2px;
    min-width: 0;
`;

const DummyIndicator = styled.div`
    height: 2px;
    min-width: 0;
    padding-block-start: ${spacing(1)};
`;

const Body = styled.div``;