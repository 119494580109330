import {media, Spacer} from '@nfq/react-grid';
import styled from 'styled-components';

import {GridItem} from 'UI/components/layout/GridItem';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Displays the control features of a device, specifically focusing on button functionalities located on the device's earpieces.
 * This component organizes information about various controls such as volume adjustment, playback management, call handling,
 * noise cancellation activation, and Bluetooth connectivity in a structured and animated manner.
 * The `InViewAnimContainer` is used to apply entrance animations, enhancing user engagement as they view the control instructions.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier used for testing purposes, ensuring the component can be specifically targeted in tests.
 * @returns A JSX.Element that arranges control instructions in a grid, with each control feature described in detail within `GridItem` components.
 *
 * @example
 * ```tsx
 * <Controls testId="device-controls-1" />
 * ```
 */
const Controls = ({testId}: ComponentProps) => (
    <InViewAnimContainer baseComponent={Wrapper} data-cy={testId} stagger={0.1}>
        <GridItem label="Multifunktionstaste (rechte Hörmuschel)" testId="multifunktionstaste-item">
            Nach oben und unten schieben, um die Lautstärke einzustellen.
            <Spacer y={4} />
            Einmal drücken, um die Wiedergabe zu starten/anzuhalten.
            <Spacer y={4} />
            Zweimal drücken, um einen Song zu überspringen.
            <Spacer y={4} />
            Dreimal drücken, um den vorherigen Song abzuspielen.
            <Spacer y={4} />
            Einmal drücken, um einen Anruf anzunehmen.
            <Spacer y={4} />
            Lange gedrückt halten, um einen Anruf abzulehnen.
        </GridItem>
        <GridItem label="Geräuschunterdrückung (rechte Hörmuschel)" testId="geräuschunterdrückung-item">
            Drücken, um zwischen aktiver Geräuschunterdrückung und Aware Modus zu wechseln.
            <Spacer y={4} />
            Lange gedrückt halten, um den Sprachassistenten zu aktivieren.
        </GridItem>
        <GridItem label="Einschalt-/Bluetooth-Taste (linke Hörmuschel)" testId="bluetooth-item">
            Drücken zum Einschalten und um ein Gerät zu verbinden.
            <Spacer y={4} />
            Lange gedrückt halten, um weitere Geräte zu verbinden.
        </GridItem>
    </InViewAnimContainer>
);

Controls.hash = '#controls';
Controls.displayName = 'Controls';
Controls.defaultProps = {testId: 'Controls'};

export {Controls};

const Wrapper = styled.div`
    display: grid;
    grid-gap: 5.6rem;
    grid-template-columns: 1fr;
    width: 100%;

    ${media('sm')} {
        grid-template-columns: 1fr 1fr;
    }

    ${media('lg')} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;