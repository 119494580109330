import {Container, media, Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Button} from 'UI/components/action/Button';
import {Picture} from 'UI/components/layout/Picture';
import {Color, H1XXXL, H5} from 'UI/components/layout/Text';
import {Video} from 'UI/components/layout/Video';

import {headerWrapperTransition, textWrapperTransition} from 'UI/animations/hero';
import {heroVideo} from 'UI/assets/videos/hero';
import {LogoCarousel} from 'UI/modules/LogoCarousel';

import {useHero} from './useHero';
import {heroImage} from 'Images/hero';
import {Logo} from 'Images/icons';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a responsive hero component tailored to provide an engaging introduction for a product or brand. This component
 * adapts its layout and elements based on the device type, using the `useHero` hook to determine whether to apply mobile or
 * desktop configurations. It features dynamically styled text, images, and optional video content to effectively capture user
 * attention. The component structure includes a logo, product name animation, a compelling headline, and a call-to-action button.
 * The hero component is designed to make a strong visual impact, with animations and transitions that enhance the user experience
 * and draw attention to the product's key features and benefits.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that displays a hero section, designed to be the focal point at the top of a webpage, promoting key product features.
 *
 * @example
 * ```tsx
 * <Hero testId="hero-section" />
 * ```
 */
const Hero = ({testId}: ComponentProps) => {
    const {colors, isMobile, videoRef} = useHero();

    return (
        <Wrapper key={isMobile ? 'mobileHero' : 'desktopHero'} data-cy={testId}>
            <Spacer y={25} isNotStretching />
            <StyledContainer maxWidth={CONTAINER_FULLSIZE_WIDTH}>
                <HeadlineWrapper
                    custom={isMobile}
                    variants={headerWrapperTransition}
                >
                    <StyledLogo />
                    <AnimatedProductNameWrapper>
                        <StyledH1>Ace</StyledH1>
                    </AnimatedProductNameWrapper>
                </HeadlineWrapper>

                <AnimatedPictureWrapper>
                    <Picture alt="SONOS Ace" loading="eager" src={heroImage} />
                </AnimatedPictureWrapper>

                <TextWrapper
                    custom={isMobile}
                    variants={textWrapperTransition}
                >
                    <Spacer y={{md: 0, xs: 4}} isNotStretching />
                    <StyledText as="p">
                        <Color $color={colors.secondaryFontColor}>
                            Sound und Style in Perfektion - jetzt im Handel erhältlich.
                        </Color>
                    </StyledText>
                    <Spacer y={{md: 10, xs: 10}} isNotStretching />
                    <Button
                        as="link"
                        href="/kaufen"
                        width={isMobile ? 'full' : 'auto'}
                    >
                        Sonos Ace kaufen
                    </Button>
                </TextWrapper>
            </StyledContainer>
            <StyledVideo ref={videoRef} src={heroVideo} loop muted playsInline />
            <Spacer y={{md: 0, xs: 14}} isNotStretching />
            <StyledLogoCarousel testId="logo-carousel" />
            <Spacer y={{md: 16, xs: 14}} isNotStretching />
        </Wrapper>
    );
};

Hero.displayName = 'Hero';
Hero.defaultProps = {testId: 'Hero'};

export {Hero};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    z-index: 1;

    ${media('md')} {
        min-height: 100dvh;
    }
`;

const StyledContainer = styled(Container)`
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    justify-content: center;

    ${media('md')} {
        flex-direction: row;
        justify-content: center;
    }
`;

const StyledLogo = styled(Logo)`
    height: auto;
    width: 10.8rem;

    ${media('md')} {
        width: 14.4rem;
    }
`;

const StyledVideo = styled(Video)`
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
`;

const AnimatedPictureWrapper = styled(motion.div)`
    height: 100%;
    order: -1;
    text-align: center;
    width: 100%;

    ${media('md')} {
        flex: 0 1 40rem;
        max-width: 40%;
        min-width: 30%;
        order: unset;
        position: relative;
        width: 40rem;
    }

    picture {
        display: inline-block;
    }

    img {
        margin: auto;
        width: 80%;

        ${media('md')} {
            max-height: 100%;
            max-width: 110%;
            transform: translateX(-20%);
            width: 55rem;
            z-index: 10;
        }
    }
`;

const AnimatedProductNameWrapper = styled(motion.div)``;

const HeadlineWrapper = styled(motion.div)`
    text-align: center;

    ${media('md')} {
        text-align: left;
        container-type: inline-size;
        flex: 0 1 auto;
        height: 23dvw;
        width: 650px;
        transform: translateY(-7%);
    }
    ${media('xl')} {
        height: 29rem;
    }
`;
const TextWrapper = styled(motion.div)`
    ${media('md')} {
        flex: 0 1 auto;
        min-width: 0%;
        opacity: 0;
        transform: translateY(15px);
        width: 0rem;
    }
`;

const StyledH1 = styled(H1XXXL)`
    font-size: 12rem;
    line-height: 1;
    text-align: center;

    ${media('md')} {
        font-size: 55cqw;
        text-align: left;
    }
`;

const StyledLogoCarousel = styled(LogoCarousel)`
    ${media('md')} {
        flex: 0 0 auto;
    }
`;

const StyledText = styled(H5)`
    text-align: center;

    ${media('md')} {
        text-align: left;
    }
`;