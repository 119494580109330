/* eslint-disable max-len */
import React from 'react';

interface SwitchProps {
    /**
     * A string representing the CSS class to be applied to the SwitchIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the SwitchIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the SwitchIcon element.
     */
    width?: number | string;
}

/**
 * The `Switch` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `SwitchProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the SwitchIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the SwitchIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the SwitchIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const SwitchComponent = <Switch className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Switch = React.forwardRef<SVGSVGElement, SwitchProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 36 36"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect fill="transparent" height="33.7" rx="16.85" stroke={color1} strokeWidth="2" width="33.7" x="1" y="1.08" />
        </svg>
    );
});

Switch.displayName = 'Switch';
Switch.defaultProps = {
    className: '',
    color1: '#141414',
    height: 36,
    testId: 'Switch',
    width: 36
};