import {Col, Container, media, Row, spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {InlineTextLink} from 'UI/components/action/InlineTextLink';
import {P} from 'UI/components/layout/Text';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders the footnotes section of a webpage, providing additional details or requirements related to the content presented above.
 * The `Footnotes` component plays a crucial role in offering detailed explanations or disclaimers that complement the main content.
 * It is designed to be flexible and can include any number of footnotes, each marked by a superscript number that corresponds to
 * references in the main text. This component ensures that users have access to all necessary information for a complete understanding
 * of the product or service features mentioned. The footnotes are presented in a smaller font size to distinguish them from the main content
 * while remaining legible. This approach helps maintain the flow of the primary content without overcrowding it with details.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for testing purposes, aiding in the reliable targeting of the footnotes component in tests.
 * @returns A JSX.Element that renders the footnotes section, providing additional information or clarifications related to the main content.
 *
 * @example
 * ```tsx
 * <Footnotes testId="product-footnotes" />
 * ```
 */
const Footnotes = ({testId}: ComponentProps) => (
    <StyledContainer testId={testId}>
        <Row>
            <Col>
                <P $size="tiny">
                    ¹ Für 3D-Audio sind ein kompatibles Gerät und kompatible Inhalte erforderlich.
                    Auf{' '}
                    <InlineTextLink
                        href="https://faq.sonos.com/spatial-requirements"
                        rel="nofollow noindex noopener noreferrer"
                        target="_blank"
                    >
                        faq.sonos.com/spatial-requirements
                    </InlineTextLink>{' '}
                    erhältst du weitere Informationen. Der Sonos Ace muss in der Sonos App registriert sein, um
                    dynamisches Head Tracking zu aktivieren.
                </P>
                <P $size="tiny">
                    ² Für verlustfreies Audio sind ein kompatibles Gerät und kompatible Inhalte
                    erforderlich. Auf {' '}
                    <InlineTextLink
                        href="https://faq.sonos.com/lossless-requirements"
                        rel="nofollow noindex noopener noreferrer"
                        target="_blank"
                    >
                        faq.sonos.com/lossless-requirements
                    </InlineTextLink>{' '}
                    erhältst du weitere Informationen.
                </P>
                <P $size="tiny">
                    ³ Die Akkulaufzeit hängt von der Nutzung, den Einstellungen und weiteren Faktoren ab.
                    Schnelles Aufladen ab 0 % Akkuladung. Bis zu 3 Stunden Ladezeit für ein vollständiges Aufladen.
                </P>
            </Col>
        </Row>
    </StyledContainer>
);

Footnotes.displayName = 'Footnotes';
Footnotes.defaultProps = {testId: 'Footnotes'};

export {Footnotes};

const StyledContainer = styled(Container)`
    padding-block: ${spacing(14)};

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;