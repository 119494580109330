import {Children} from 'react';

import {media, Spacer, spacing, translucify} from '@nfq/react-grid';
import AutoScroll from 'embla-carousel-auto-scroll';
import styled from 'styled-components';

import {useSlider} from './useSlider';
import {ArrowRight} from 'Images/icons';

import type {EmblaOptionsType} from 'embla-carousel';
import type {WithMultipleChildren} from 'types/global';


/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    autoscroll?: boolean;
    className?: string;
    hideArrows?: boolean;
    /** A boolean that indicates whether the overflow of the slider should be hidden. */
    overflowHidden?: boolean;
    sliderOptions?: EmblaOptionsType;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}


/**
 * Renders a customizable slider component that provides navigation controls for moving through multiple children elements,
 * such as images or cards. This component utilizes the `useSlider` hook to handle the sliding logic and state management, including
 * disabling navigation buttons when the beginning or end of the slider is reached. The component's layout and functionality adapt
 * based on the device's screen size, with icon sizes adjusted accordingly. It is designed to be flexible, allowing for any React node
 * to be passed as children. Additionally, it offers an optional `overflowHidden` prop to control whether the overflow of the slider
 * content is hidden, which can be useful for creating cleaner, bounded slider interfaces.
 *
 * @param props                The component props.
 * @param props.children       The elements to be displayed within the slider. This can be any React node.
 * @param props.overflowHidden A boolean that indicates whether the overflow of the slider should be hidden.
 * @param props.testId         A unique identifier for the component, useful for testing purposes.
 * @param props.className      The class name to apply to the component.
 * @param props.sliderOptions  The options to pass to the Embla Carousel instance.
 * @param props.autoscroll     A boolean that indicates whether the slider should autoscroll.
 * @param props.hideArrows     A boolean that indicates whether the navigation arrows should be hidden.
 * @returns A React component that displays a slider with optional overflow hiding and responsive navigation controls.
 *
 * @example
 * ```tsx
 * <Slider testId="custom-slider" overflowHidden={true}>
 *   <div>Slide 1</div>
 *   <div>Slide 2</div>
 *   <div>Slide 3</div>
 * </Slider>
 * ```
 */
const Slider = ({
    autoscroll,
    children,
    className,
    hideArrows,
    overflowHidden,
    sliderOptions,
    testId
}: WithMultipleChildren<ComponentProps>) => {
    const {
        iconSize,
        nextBtnDisabled,
        onNextButtonClick,
        onPrevButtonClick,
        prevBtnDisabled,
        slideRef
    } = useSlider({
        align: 'start',
        ...sliderOptions
    }, autoscroll ? [AutoScroll({
        playOnInit: true,
        speed: 1,
        startDelay: 0,
        stopOnFocusIn: false,
        stopOnInteraction: false,
        stopOnMouseEnter: false
    })] : []);

    // eslint-disable-next-line @nfq/no-magic-numbers

    return (
        <SliderWrapper className={className} data-cy={testId}>
            {(!nextBtnDisabled || !prevBtnDisabled) && !hideArrows && (
                <>
                    <Controls>
                        <ArrowButton
                            $direction="prev"
                            aria-label="Previous"
                            disabled={prevBtnDisabled}
                            onClick={onPrevButtonClick}
                        >
                            <ArrowRight color1="currentColor" height={iconSize} width={iconSize} />
                        </ArrowButton>
                        <ArrowButton
                            $direction="next"
                            aria-label="Next"
                            disabled={nextBtnDisabled}
                            onClick={onNextButtonClick}
                        >
                            <ArrowRight color1="currentColor" height={iconSize} width={iconSize} />
                        </ArrowButton>
                    </Controls>
                    <Spacer y={5} />
                </>
            )}
            <Viewport ref={slideRef} $overflowHidden={overflowHidden}>
                <Container>
                    {Children.map(children, (child, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Slide key={index}>
                            {child}
                        </Slide>
                    ))}
                </Container>
            </Viewport>
        </SliderWrapper>
    );
};

Slider.displayName = 'Slider';
Slider.defaultProps = {
    className: '',
    testId: 'Slider'
};

export {Slider};

const SliderWrapper = styled.div`
    width: 100%;
`;

interface ViewportProps {
    $overflowHidden?: boolean;
}

const Viewport = styled.div<ViewportProps>`
    overflow: ${({$overflowHidden}) => ($overflowHidden ? 'hidden' : 'visible')};
`;

const Container = styled.div`
    backface-visibility: hidden;
    display: flex;
    margin-inline: -1.5rem;
    touch-action: pan-y;

    ${media('md')} {
        margin-inline: -4rem;
    }
`;

const Slide = styled.div`
    margin-inline: 1.5rem;
    min-width: 15rem;
    width: 15rem;

    ${media('md')} {
        margin-inline: 4rem;
        min-width: 30rem;
        width: 30rem;
    }
`;

const Controls = styled.div`
    align-items: center;
    display: flex;
    gap: ${spacing(5.5)};
    justify-content: flex-end;
`;

interface ArrowButtonProps {
    $direction: 'next' | 'prev';
}
const ArrowButton = styled.button<ArrowButtonProps>`
    background: none;
    border: none;
    color: ${({theme}) => theme.colors.primaryFontColor};
    cursor: pointer;
    outline: none;
    transform: ${({$direction}) => ($direction === 'prev' ? 'scaleX(-1)' : null)};
    transition: opacity 0.2s ease-in-out;
    will-change: opacity color;

    &:hover:not(:disabled) {
        opacity: 0.8;
    }

    &:disabled {
        background: none;
        color: ${({theme}) => translucify(theme.colors.primaryFontColor, 40)};
        cursor: not-allowed;
    }
`;