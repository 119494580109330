import type {TabHeadItems} from 'UI/components/action/Tabs';

/**
 * Provides a static list of tab head items used for rendering tab headers in a tabbed interface.
 * Each item in the list contains a `hash` that acts as a unique identifier for each tab, and a `label` which provides the visible name displayed on the tab.
 * This function is particularly useful when you need to set up a consistent tab navigation structure across components or pages where the tabs' content and labels do not change dynamically.
 *
 * @returns An array of objects, each containing a `hash` and `label` for a tab. These items are used to render the tabs and handle navigation between the tabbed content.
 *
 * @example
 * ```tsx
 * const tabItems = getTabHeadItems();
 * // tabItems will be an array of objects, each with a `hash` and `label` property.
 * ```
 */
export const getTabHeadItems = (): TabHeadItems[] => [
    {
        hash: '#audio',
        label: 'Audio'
    },
    {
        hash: '#details',
        label: 'Details und Maße'
    },
    {
        hash: '#controls',
        label: 'Steuerung'
    },
    {
        hash: '#energy',
        label: 'Konnektivität'
    },
    {
        hash: '#delivery',
        label: 'Lieferumfang'
    }
];