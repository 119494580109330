import {Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Color, P} from 'UI/components/layout/Text';

import {FadeInAnimation} from 'UI/animations/shared';
import {useThemeColors} from 'UI/hooks/useThemeColors';

import type {
    ActiveNoiseCancelling,
    DolbyAtmos,
    DynamicHeadtracking,
    Equalizer,
    LosslessAudio,
    Mikrofon,
    SpatialAudio,
    Switch,
    Treiber
} from 'Images/icons';

import type {WithChildren} from 'types/global';


/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * An optional React component for an icon, which receives `height` and `width` as props.
     */
    Icon?: typeof ActiveNoiseCancelling | typeof DolbyAtmos | typeof DynamicHeadtracking | typeof Equalizer
    | typeof LosslessAudio | typeof Mikrofon | typeof SpatialAudio | typeof Switch | typeof Treiber;
    /**
     * The text label displayed above the additional descriptive text.
     */
    label: string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Represents a grid item component designed for displaying icons, labels, and additional text.
 * This component includes an optional icon, a label, and child components which are typically additional descriptive text.
 * The use of `FadeInAnimation` provides an animation effect on viewport entry, enhancing visual appeal.
 * This component is ideal for use in grid layouts where each item needs to convey a concise piece of information
 * accompanied by visual identifiers (icons) and additional explanatory text. It also uses theme colors to style the text,
 * ensuring consistent styling aligned with the rest of the application or page.
 *
 * @param props          The component props.
 * @param props.children The children passed to the component, usually additional descriptive text.
 * @param props.Icon     An optional React component for an icon, which receives `height` and `width` as props.
 * @param props.label    The text label displayed above the additional descriptive text.
 * @param props.testId   A unique identifier for testing purposes.
 * @returns A JSX element styled as a grid item with optional icon, label, and children text.
 *
 * @example
 * ```tsx
 * <GridItem Icon={ExampleIcon} label="Example Label" testId="example-grid-item">
 *   Additional descriptive text here.
 * </GridItem>
 * ```
 */
const GridItem = ({children, Icon, label, testId}: WithChildren<ComponentProps>) => {
    const colors = useThemeColors();

    return (
        <Wrapper data-cy={testId} variants={FadeInAnimation}>
            {Icon && (
                <>
                    <Icon height={40} width={40} />
                    <Spacer y={4} />
                </>
            )}
            <P>{label}</P>
            <Spacer y={4} />
            <StyledP $size="small">
                <Color $color={colors.secondaryFontColor}>
                    {children}
                </Color>
            </StyledP>
        </Wrapper>
    );
};

GridItem.displayName = 'GridItem';
GridItem.defaultProps = {testId: 'GridItem'};

export {GridItem};

const Wrapper = styled(motion.div)``;
const StyledP = styled(P)`
    max-width: 30rem;
`;