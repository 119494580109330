import {useScreenSize} from '@nfq/react-grid';

import {Slider} from 'UI/components/action/Slider/Slider';

import {ElectronicPartner, Euronics, Expert, MediaMarkt, Tink} from 'Images/logos';

import type {EmblaOptionsType} from 'embla-carousel';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    className?: string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a `LogoCarousel` component that displays a sequence of partner logos in a sliding format. This component adapts to different screen sizes,
 * setting the width of the logos accordingly to ensure optimal display on both mobile and desktop devices. The carousel is implemented using a `Slider`
 * component, configured to align logos centrally, allow continuous looping, and disable free dragging for a consistent user experience.
 * This setup is ideal for showcasing partner or sponsor logos on a website, promoting brand visibility in a dynamic and engaging manner.
 *
 * The carousel also features autoplay functionality and hides navigation arrows to maintain a clean and focused presentation of logos.
 * This component accepts `className` for custom styling and `testId` for specific testing purposes, making it versatile for integration into various web environments.
 *
 * @param props           The component props.
 * @param props.className Optional CSS class for additional styling.
 * @param props.testId    A unique identifier for the component, useful for testing purposes.
 * @returns A React component that provides an automated, looping carousel of logos, visually adjusted for different devices.
 *
 * @example
 * ```tsx
 * <LogoCarousel className="custom-carousel-style" testId="logo-carousel" />
 * ```
 */
const LogoCarousel = ({className, testId}: ComponentProps) => {
    const breakpoint = useScreenSize();

    const isMobile = ['xs', 'sm'].includes(breakpoint);
    // eslint-disable-next-line @nfq/no-magic-numbers
    const width = isMobile ? 150 : 192;
    // eslint-disable-next-line @nfq/no-magic-numbers
    const height = isMobile ? 50 : 64;

    const sliderOptions: EmblaOptionsType = {
        align: 'center',
        loop: true,
        watchDrag: false,
        watchResize: false
    };

    return (
        <div className={className} data-cy={testId}>
            <Slider sliderOptions={sliderOptions} autoscroll hideArrows>
                <Euronics height={height} width={width} />
                <MediaMarkt height={height} width={width} />
                <Tink height={height} width={width} />
                <Expert height={height} width={width} />
                <ElectronicPartner height={height} width={width} />
                {/* Duplicate logos for endless scroll */}
                <Euronics height={height} width={width} />
                <MediaMarkt height={height} width={width} />
                <Tink height={height} width={width} />
                <Expert height={height} width={width} />
                <ElectronicPartner height={height} width={width} />
                <Euronics height={height} width={width} />
                <MediaMarkt height={height} width={width} />
                <Tink height={height} width={width} />
                <Expert height={height} width={width} />
                <ElectronicPartner height={height} width={width} />
            </Slider>
        </div>
    );
};

LogoCarousel.displayName = 'LogoCarousel';
LogoCarousel.defaultProps = {testId: 'LogoCarousel'};

export {LogoCarousel};