import lgMp4 from './hero_bg_lg.mp4';
import lgWebm from './hero_bg_lg.webm';
import xsMp4 from './hero_bg_xs.mp4';
import xsWebm from './hero_bg_xs.webm';


export const heroVideo = {
    lg: {
        mp4: lgMp4,
        webm: lgWebm
    },
    xs: {
        mp4: xsMp4,
        webm: xsWebm
    }
};