import type {ComponentPropsWithRef, ComponentType} from 'react';

import {m as motion} from 'framer-motion';

import {InViewPortTrigger} from 'UI/animations/shared';

import type {WithOptionalChildren} from 'types/global';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface BaseProps<T> {
    /**
     * The base component that will be animated upon coming into view.
     */
    baseComponent: T;
    onViewportEnter?(): void;
    onViewportLeave?(): void;
    /**
     * The stagger value for the animation, determining the delay between each child element.
     */
    stagger: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * The `InViewAnimContainer` component is a generic animated container that leverages the Framer Motion library to
 * trigger animations when the component enters the viewport. It wraps any base component passed to it, allowing for
 * versatile use across different parts of an application. This component is particularly useful for enhancing user
 * experience with entrance animations, making page elements appear dynamically as the user scrolls. The animation
 * starts once a specified portion of the component is visible, and this can be configured per use case. It also
 * supports all standard properties of the base component via TypeScript generics, making it flexible and adaptable
 * to various use cases.
 *
 * @param props                          The props passed to the component.
 * @param props.ComponentPropsWithRef<T> Additional props specific to the base component, supporting ref forwarding.
 * @param props.baseComponent            The base component that will be animated upon coming into view.
 * @param props.children                 The children to be rendered inside the base component.
 * @param props.stagger                  The stagger value for the animation, determining the delay between each child element.
 * @param props.testId                   A unique identifier for the component instance, used primarily for testing purposes.
 * @param props.onViewportEnter          A function to be called when the component enters the viewport.
 * @param props.onViewportLeave          A function to be called when the component leaves the viewport.
 * @returns A motion-enhanced component that triggers animations when entering the viewport.
 *
 * @example
 * ```tsx
 * <InViewAnimContainer baseComponent={Div} testId="animated-div">
 *     <p>Content inside an animated container</p>
 * </InViewAnimContainer>
 * ```
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
const InViewAnimContainer = <T,>(
    {
        baseComponent,
        children,
        onViewportEnter,
        onViewportLeave,
        stagger,
        testId,
        ...props
    // @ts-expect-error
    }: WithOptionalChildren<BaseProps<T> & ComponentPropsWithRef<T>>
) => {
    const Base = motion(baseComponent as ComponentType);

    return (
        <Base
            custom={stagger}
            data-cy={testId}
            initial="outOfView"
            variants={InViewPortTrigger}
            viewport={{
                amount: 'some',
                once: true
            }}
            whileInView="inView"
            onViewportEnter={onViewportEnter}
            onViewportLeave={onViewportLeave}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            {children}
        </Base>
    );
};

InViewAnimContainer.displayName = 'InViewAnimContainer';
InViewAnimContainer.defaultProps = {
    stagger: 0.2,
    testId: 'InViewAnimContainer'
};

export {InViewAnimContainer};