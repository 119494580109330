import {useEffect, useRef} from 'react';

import {useScreenSize} from '@nfq/react-grid';

import {useThemeColors} from 'UI/hooks/useThemeColors';


/**
 * A custom React hook that provides theme colors and device type information specifically for styling hero sections
 * within a website or application. This hook utilizes `useThemeColors` to fetch the current theme colors and `useScreenSize`
 * to determine the device type based on screen size. It effectively supports responsive design by determining if the device
 * is categorized as mobile (small or extra small). This hook is particularly useful for components that need to adjust their
 * styling or layout dynamically based on the user's device and color theme preferences.
 *
 * @returns An object containing:
 * - `colors`: An object containing the theme colors, useful for applying consistent styling across the hero component.
 * - `isMobile`: A boolean indicating whether the current device is classified as mobile (either 'xs' or 'sm' breakpoint), allowing for mobile-specific adjustments to the hero section.
 *
 * @example
 * ```ts
 * const { colors, isMobile } = useHero();
 * ```
 */
export const useHero = () => {
    const colors = useThemeColors();
    const breakpoint = useScreenSize();
    const isMobile = ['xs', 'sm'].includes(breakpoint);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            void videoRef.current.play();
        }
    }, []);

    return {
        colors,
        isMobile,
        videoRef
    };
};