import {useConfig} from '@nfq/react-grid';
import styled from 'styled-components';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The alternative text for the image, providing a text description for users who cannot see the image.
     */
    alt: string;
    /**
     * Optional CSS class name for applying custom styles to the image.
     */
    className: string;
    /**
     * The loading strategy for the image, specifying when the image should be loaded.
     */
    loading: 'eager' | 'lazy';
    /**
     * An object mapping screen size keys to image source URLs, ensuring the image is responsive to screen size changes.
     */
    src: {
        lg?: string;
        md?: string;
        sm?: string;
        xl?: string;
        xs: string;
        xxl?: string;
    };
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders an image with responsive source sets based on different breakpoints.
 * The `Picture` component dynamically selects the appropriate image source for various viewport widths,
 * optimizing loading times and ensuring visual quality across devices. It utilizes the `useConfig` hook to
 * access the application's breakpoint configuration, then maps over these breakpoints to generate `source` elements
 * for the `picture` tag. The component gracefully falls back to the `xs` (extra small) image source if specific
 * breakpoint sources are not provided. This approach facilitates efficient image management and supports responsive design principles.
 *
 * @param props           The component props.
 * @param props.alt       The alternative text for the image, enhancing accessibility by providing a text alternative for screen readers.
 * @param props.className Optional CSS class to apply custom styling to the image.
 * @param props.src       An object containing image sources for various breakpoints, ensuring optimal image selection based on viewport width.
 * @param props.testId    A unique identifier for testing purposes, ensuring the component can be precisely targeted within test suites.
 * @param props.loading   The loading strategy for the image, specifying when the image should be loaded.
 * @returns A responsive `picture` element that dynamically loads the appropriate image source based on viewport width.
 *
 * @example
 * ```tsx
 * <Picture
 *   alt="Descriptive text for the image"
 *   className="custom-image-class"
 *   src={{ xs: '/path/to/default.jpg', md: '/path/to/medium.jpg', lg: '/path/to/large.jpg' }}
 *   testId="unique-picture-id"
 * />
 * ```
 */
const Picture = ({alt, className, loading, src, testId}: ComponentProps) => {
    const config = useConfig();
    const {breakpoints} = config;

    return (
        <picture data-cy={testId}>
            {Object.entries(breakpoints).sort(([,a], [,b]) => b - a).map(([key, value]) => {
                if (key === 'xs') return null;
                if (src[key as keyof typeof src] === undefined) return null;

                return <source key={key} media={`(min-width: ${value}px)`} srcSet={src[key as keyof typeof src]} />;
            })}
            <StyledImage alt={alt} className={className} loading={loading} role="presentation" src={src.xs} />
        </picture>
    );
};

Picture.displayName = 'Picture';
Picture.defaultProps = {
    className: '',
    loading: 'lazy' as const,
    testId: 'Picture'
};

export {Picture};

const StyledImage = styled.img`
    object-fit: contain;
`;