import {useState} from 'react';

import {useThemeColors} from 'UI/hooks/useThemeColors';

import type {TabComponent} from 'types/global';


/**
 * A custom hook for managing tabbed interfaces, providing mechanisms to handle tab state, selection, and content rendering.
 * This hook initializes with a default active tab and maintains the state of the currently active tab. It allows switching between tabs by updating the active tab state.
 * Additionally, it provides the actively displayed child component based on the active tab identifier, which corresponds to a unique hash of the tab.
 * This hook is useful for creating tabbed components where the content needs to be switched dynamically based on user interaction.
 *
 * @param initialTab The identifier of the tab that should be active initially.
 * @param children   An array of child components, each representing the content of a tab, associated by a hash property.
 * @returns An object containing the active child component, color theming from `useThemeColors`, a function to handle tab switching, and the current active tab identifier.
 *
 * @example
 * ```tsx
 * const tabComponents = [
 *   { type: { hash: 'tab1' }, content: <div>Content for Tab 1</div> },
 *   { type: { hash: 'tab2' }, content: <div>Content for Tab 2</div> }
 * ];
 * const {activeChild, handleTab, tab} = useTabs('tab1', tabComponents);
 * // activeChild will initially be the content for 'tab1', and `tab` will be 'tab1'.
 * // `handleTab` can be called with a new hash to change the active tab.
 * ```
 */
export const useTabs = (initialTab: string, children: TabComponent[]) => {
    const colors = useThemeColors();
    const [tab, setTab] = useState(initialTab);
    const activeChild = children.find(child => child.type.hash === tab);

    /**
     * Changes the active tab to the one specified by the hash.
     * This function updates the internal state of the active tab identifier to the provided hash value, triggering a re-render of the dependent components.
     *
     * @param hash The hash identifier of the tab to make active.
     */
    const handleTab = (hash: string) => {
        setTab(hash);
    };

    return {
        activeChild,
        colors,
        handleTab,
        tab
    };
};