import {media} from '@nfq/react-grid';
import styled from 'styled-components';

import {InlineTextLink} from 'UI/components/action/InlineTextLink';
import {GridItem} from 'UI/components/layout/GridItem';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';

import {
    ActiveNoiseCancelling,
    DolbyAtmos,
    DynamicHeadtracking,
    Equalizer,
    LosslessAudio,
    Mikrofon,
    SpatialAudio,
    Switch,
    Treiber
} from 'Images/icons';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Constructs a section for displaying audio features using multiple `GridItem` components, each describing a specific audio-related technology or feature.
 * This component acts as a container for a series of grid items, each represented by an icon and accompanying text that explains the feature.
 * The `InViewAnimContainer` is used to wrap these grid items, providing an animation on viewport entry which enhances the visual interaction of the section.
 * The `Audio` component is perfect for detailing the specifications and capabilities of audio devices, such as headphones, in an engaging and structured manner.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for testing purposes.
 * @returns A JSX element that groups several grid items, each detailing a different aspect of the audio technology in a product.
 *
 * @example
 * ```tsx
 * <Audio testId="audio-specs" />
 * ```
 */
const Audio = ({testId}: ComponentProps) => (
    <InViewAnimContainer baseComponent={Wrapper} data-cy={testId} stagger={0.1}>
        <GridItem Icon={Treiber} label="Treiber" testId="treiber-item">
            Speziell entwickelte dynamische 40-mm-Treiber in jeder Hörmuschel.
        </GridItem>
        <GridItem Icon={Mikrofon} label="Mikrofone" testId="mikrofone-item">
            Acht Mikrofone sorgen für aktive Geräuschunterdrückung und heben deine Stimme hervor.
        </GridItem>
        <GridItem Icon={SpatialAudio} label="3D-Audio" testId="3Daudio-item">
            Streame 3D-Audio Inhalte unterstützter Dienste von kompatiblen Geräten.<br />
            <br />
            <InlineTextLink
                href="https://faq.sonos.com/sonos-ace/spatial-audio"
                rel="nofollow noindex noopener noreferrer"
                target="_blank"
            >
                Mehr erfahren
            </InlineTextLink>
        </GridItem>
        <GridItem Icon={LosslessAudio} label="Verlustfreies Audio" testId="losslessaudio-item">
            Erlebe verlustfreies Audio über Bluetooth mit einem unterstützten Gerät oder per USB-C-Verbindung.<br />
            <br />
            <InlineTextLink
                href="https://faq.sonos.com/sonos-ace/lossless"
                rel="nofollow noindex noopener noreferrer"
                target="_blank"
            >
                Mehr erfahren
            </InlineTextLink>
        </GridItem>
        <GridItem
            Icon={ActiveNoiseCancelling}
            label="Geräuschunterdrückung (ANC)"
            testId="activenoisecancelling-item"
        >
            Aktive Geräuschunterdrückung blockiert externe Geräusche. Im Aware Modus genießt du deine Inhalte
            und bekommst dennoch alles mit, was in deiner Umgebung passiert.<br />
            <br />
            <InlineTextLink
                href="https://faq.sonos.com/sonos-ace/anc"
                rel="nofollow noindex noopener noreferrer"
                target="_blank"
            >
                Mehr erfahren
            </InlineTextLink>
        </GridItem>
        <GridItem Icon={DynamicHeadtracking} label="Dynamisches Head Tracking" testId="dynamicheadtracking-item">
            Intelligent Motion Processing von Sonos mit Dolby Head Tracking.
            Aktiviere dieses Feature in der Sonos App.<br />
            <br />
            <InlineTextLink
                href="https://faq.sonos.com/sonos-ace/head-tracking"
                rel="nofollow noindex noopener noreferrer"
                target="_blank"
            >
                Mehr erfahren
            </InlineTextLink>
        </GridItem>
        <GridItem Icon={Switch} label="Switche TV Sound" testId="switchsound-item">
            Drücke die Multifunktionstaste, um Sound von ausgewählten Sonos Soundbars zum Sonos Ace zu switchen.<br />
            <br />
            <InlineTextLink
                href="https://faq.sonos.com/sonos-ace/tv-swap"
                rel="nofollow noindex noopener noreferrer"
                target="_blank"
            >
                Mehr erfahren
            </InlineTextLink>
        </GridItem>
        <GridItem Icon={DolbyAtmos} label="Dolby Atmos" testId="dolbyatmos-item">
            Unterstützte Sonos Soundbar und Sonos App erforderlich.<br />
            <br />
            <InlineTextLink
                href="https://faq.sonos.com/sonos-ace/atmos"
                rel="nofollow noindex noopener noreferrer"
                target="_blank"
            >
                Mehr erfahren
            </InlineTextLink>
        </GridItem>
        <GridItem Icon={Equalizer} label="Verstellbarer Equalizer" testId="equalizer-item">
            Mit der Sonos App stellst du ganz einfach Bass, Höhen und Lautstärke ein.
        </GridItem>
    </InViewAnimContainer>
);

Audio.hash = '#audio';
Audio.displayName = 'Audio';
Audio.defaultProps = {testId: 'Audio'};

export {Audio};

const Wrapper = styled.div`
    display: grid;
    grid-gap: 5.6rem;
    grid-template-columns: 1fr;
    width: 100%;

    ${media('sm')} {
        grid-template-columns: 1fr 1fr;
    }

    ${media('lg')} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;