import {media} from '@nfq/react-grid';
import styled from 'styled-components';

import {GridItem} from 'UI/components/layout/GridItem';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Displays the contents of the delivery package for a product, detailing each item included, such as headphones, cables, and documentation.
 * This component uses the `InViewAnimContainer` to apply entrance animations for each item as they come into view, enhancing user engagement.
 * The structure and layout are designed to provide clear and concise information about what the customer can expect to receive with their purchase.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier used for testing purposes, ensuring the component can be specifically targeted in tests.
 * @returns A JSX.Element that organizes delivery content details into a grid, with each item clearly described within `GridItem` components.
 *
 * @example
 * ```tsx
 * <Delivery testId="delivery-details-1" />
 * ```
 */
const Delivery = ({testId}: ComponentProps) => (
    <InViewAnimContainer baseComponent={Wrapper} data-cy={testId} stagger={0.1}>
        <GridItem label="Sonos Ace" testId="sonos-item">
            Sonos Ace Kopfhörer
        </GridItem>
        <GridItem label="Hülle" testId="huelle-item">
            Mit abnehmbarer Kabeltasche
        </GridItem>
        <GridItem label="USB-C-zu-USB-C-Kabel" testId="usb-item">
            0,75 m
        </GridItem>
        <GridItem label="USB-C-zu-3,5-mm-Kabel" testId="kabel-item">
            1,2 m
        </GridItem>
        <GridItem label="Dokumentation" testId="dokumentation-item">
            Kurzanleitung, rechtliche Informationen und Garantie.
        </GridItem>
    </InViewAnimContainer>
);

Delivery.hash = '#delivery';
Delivery.displayName = 'Delivery';
Delivery.defaultProps = {testId: 'Delivery'};

export {Delivery};

const Wrapper = styled.div`
    display: grid;
    grid-gap: 5.6rem;
    grid-template-columns: 1fr;
    width: 100%;

    ${media('sm')} {
        grid-template-columns: 1fr 1fr;
    }

    ${media('lg')} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;