import type {VideoHTMLAttributes} from 'react';
import {forwardRef, Fragment} from 'react';

import {useConfig} from '@nfq/react-grid';
import styled from 'styled-components';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * Optional CSS class name for applying custom styles to the video.
     */
    className?: string;
    /**
     * An object mapping screen size keys to video source URLs, ensuring the video is responsive to screen size changes.
     */
    src: {
        lg?: {mp4: string; webm: string};
        md?: {mp4: string; webm: string};
        sm?: {mp4: string; webm: string};
        xl?: {mp4: string; webm: string};
        xs: {mp4: string; webm: string};
        xxl?: {mp4: string; webm: string};
    };
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId?: string;
}

type VideoProps = Omit<VideoHTMLAttributes<HTMLVideoElement>, 'src'> & ComponentProps;

/**
 * The `Video` component is designed to render a responsive video player that adjusts based on screen size.
 * It leverages the `useConfig` hook to access global configuration details, such as breakpoints, to determine
 * the appropriate video source to load for different screen widths. The component accepts various props to
 * customize its behavior and appearance, including a `className` for custom styling, a `src` object containing
 * video sources mapped to different breakpoints, and a `testId` for testing purposes. The component is wrapped
 * with `forwardRef` to provide access to the video DOM element to the parent component, allowing for direct
 * manipulation or querying of the video element when necessary.
 *
 * @param props           The props passed to the component.
 * @param props.className Optional CSS class name for applying custom styles to the video element.
 * @param props.src       An object mapping screen size keys to video source URLs, ensuring the video is responsive to screen size changes.
 * @param props.testId    A unique identifier for the component instance, used primarily for testing purposes.
 * @param ref             A ref object pointing to the DOM element of the video player, allowing parent components to directly interact with it.
 * @returns A JSX element rendering a video player with responsive sources based on screen size.
 *
 * @example
 * ```tsx
 * <Video
 *   className="custom-video-player"
 *   src={{
 *     xs: 'path/to/video-xs.mp4',
 *     md: 'path/to/video-md.mp4',
 *     lg: 'path/to/video-lg.mp4',
 *   }}
 *   testId="video-test-id"
 *   controls
 * />
 * ```
 */
const Video = forwardRef<HTMLVideoElement, VideoProps>(({className, src, testId, ...videoProps}, ref) => {
    const config = useConfig();
    const {breakpoints} = config;

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Wrapper ref={ref} className={className} data-cy={testId} aria-hidden {...videoProps}>
            {Object.entries(breakpoints).sort(([,a], [,b]) => b - a).map(([key, value]) => {
                if (src[key as keyof typeof src] === undefined) return null;

                return (
                    <Fragment key={key}>
                        <source
                            media={`(min-width: ${value}px)`}
                            src={src[key as keyof typeof src]!.webm}
                            type="video/webm"
                        />
                        <source
                            media={`(min-width: ${value}px)`}
                            src={src[key as keyof typeof src]!.mp4}
                            type="video/mp4"
                        />
                    </Fragment>
                );
            })}
        </Wrapper>
    );
});

Video.displayName = 'Video';
Video.defaultProps = {
    className: '',
    testId: 'Video'
};

export {Video};

const Wrapper = styled.video``;