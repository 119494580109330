/* eslint-disable max-len */
import React from 'react';

interface PointerProps {
    /**
     * A string representing the CSS class to be applied to the PointerIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the PointerIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the PointerIcon element.
     */
    width?: number | string;
}

/**
 * The `Pointer` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `PointerProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the PointerIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the PointerIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the PointerIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const PointerComponent = <Pointer className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Pointer = React.forwardRef<SVGSVGElement, PointerProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 40 41"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect fill="transparent" height="37.45" rx="18.73" stroke={color1} strokeWidth="2" width="37.45" x="1" y="1.87" />
            <g>
                <path d="M14.3 19.54c-.38-.16-.57-.23-.62-.34a.4.4 0 0 1 0-.31c.05-.11.24-.2.63-.35l10.85-4.4c.34-.14.52-.21.63-.18q.15.06.2.21c.04.12-.03.3-.16.66l-4.24 11.3c-.15.39-.22.59-.33.65q-.15.07-.3 0c-.1-.06-.18-.26-.33-.66l-1.69-4.52-.07-.15-.07-.08q-.04-.04-.15-.07z" fill="transparent" stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </g>
        </svg>
    );
});

Pointer.displayName = 'Pointer';
Pointer.defaultProps = {
    className: '',
    color1: '#fff',
    height: 41,
    testId: 'Pointer',
    width: 40
};