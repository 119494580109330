/* eslint-disable max-len */
import React from 'react';

interface TreiberProps {
    /**
     * A string representing the CSS class to be applied to the TreiberIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the TreiberIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the TreiberIcon element.
     */
    width?: number | string;
}

/**
 * The `Treiber` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `TreiberProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the TreiberIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the TreiberIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the TreiberIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const TreiberComponent = <Treiber className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Treiber = React.forwardRef<SVGSVGElement, TreiberProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 80 80"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color1}>
                <path d="M66.27 74.3c-10.52 0-25.29-7.82-39.01-21.56C8.17 33.67.49 12.57 9.38 3.7c8.9-8.9 29.99-1.21 49.06 17.87S85.2 61.74 76.3 70.62c-2.5 2.5-5.94 3.68-10.05 3.68zM19.37 6.02c-2.5 0-4.48.63-5.75 1.9-5.38 5.38.65 23.36 17.87 40.59C48.72 65.73 66.7 71.75 72.08 66.38c5.37-5.37-.65-23.36-17.88-40.58C41.04 12.64 27.45 6.02 19.36 6.02" />
                <path d="M63.03 59.9c-2.13 0-4.96-.7-8.7-2.64-5.08-2.66-10.87-7.1-16.27-12.5-10.4-10.39-19-24.14-13.34-29.8 1.86-1.86 5.72-3.22 13.52.85 5.08 2.65 10.87 7.09 16.27 12.5s9.84 11.18 12.5 16.27c4.08 7.8 2.7 11.66.84 13.52a6.5 6.5 0 0 1-4.83 1.8zM29.6 19.1q-.44 0-.66.13c-.74 1.35 2.12 10.03 13.38 21.3 11.25 11.24 19.95 14.11 21.35 13.31.75-1.35-2.11-10.03-13.37-21.29-10.1-10.09-18.13-13.44-20.69-13.44m34.12 17.76c-2.78-3.8-6-7.54-9.53-11.07a96 96 0 0 0-11.07-9.53l3.54-4.84c4.06 2.97 8.02 6.37 11.77 10.13a101 101 0 0 1 10.13 11.77z" />
                <path d="M35.3 80A35.3 35.3 0 0 1 8.55 21.68l3.3-3.84 1.78 4.74c3.08 8.2 9.59 17.64 17.86 25.93 8.29 8.28 17.73 14.78 25.93 17.86l4.74 1.78-3.84 3.3A35.3 35.3 0 0 1 35.3 80M10.16 29.64A29.3 29.3 0 0 0 6 44.7 29.34 29.34 0 0 0 35.3 74c5.33 0 10.54-1.45 15.07-4.16-7.69-3.78-15.72-9.7-23.1-17.09-7.39-7.38-13.31-15.42-17.09-23.1M60 55.78 54.7 53c.52-1 .48-4.64-11.4-16.52-5.16-5.17-11.6-8.95-14.94-8.76l-.3-5.99c6.31-.3 14.44 5.47 19.49 10.51C56 40.68 63.36 49.4 60 55.8" />
            </g>
        </svg>
    );
});

Treiber.displayName = 'Treiber';
Treiber.defaultProps = {
    className: '',
    color1: '#212325',
    height: 80,
    testId: 'Treiber',
    width: 80
};