import {Container, media, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';


import {Accordion} from 'UI/components/action/Accordion/Accordion';
import {InlineTextLink} from 'UI/components/action/InlineTextLink';
import {H3, P} from 'UI/components/layout/Text';

import {FadeInAnimation} from 'UI/animations/shared';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a FAQ section for a product or service, specifically designed for the Sonos Ace. This component creates an
 * accordion-style list of frequently asked questions (FAQs), where each question can be expanded or collapsed to reveal
 * the answer. It uses the `Accordion` component to create each FAQ entry, making it easy for users to find information
 * about common queries regarding the Sonos Ace, such as setup requirements, app functionality, connectivity options,
 * and compatibility with other devices. Placeholder text "TBD" is used for answers that are yet to be determined or added.
 *
 * @param props        The props passed to the component.
 * @param props.testId A unique identifier for the component instance, used primarily for testing purposes.
 * @returns A JSX element rendering a list of FAQs about the Sonos Ace, each contained in an expandable accordion section.
 *
 * @example
 * ```tsx
 * <Faq testId="sonos-ace-faq" />
 * ```
 */
// eslint-disable-next-line max-lines-per-function
const Faq = ({testId}: ComponentProps) => (
    <StyledContainer data-cy={testId} id="faq">
        <H3 as="h2">FAQ</H3>
        <motion.div
            initial="outOfView"
            variants={FadeInAnimation}
            viewport={{
                amount: 0.4,
                once: true
            }}
            whileInView="inView"
        >
            <Accordion title="Benötige ich die Sonos App, um den Sonos Ace einzurichten und zu nutzen?">
                <P $size="medium">
                    Nein. Der Sonos Ace nutzt Bluetooth, um sich mit Geräten zu verbinden.<br />
                    Allerdings müssen bestimmte Features mit der Sonos App
                    auf einem Gerät mit Internetverbindung aktiviert werden.
                </P>
            </Accordion>
        </motion.div>
        <motion.div
            initial="outOfView"
            variants={FadeInAnimation}
            viewport={{
                amount: 0.4,
                once: true
            }}
            whileInView="inView"
        >
            <Accordion
                // eslint-disable-next-line max-len
                title="Funktioniert die Sonos App mit dem Sonos Ace, wenn ich nicht mit meinem WLAN-Heimnetzwerk verbunden bin?"
            >
                <P $size="medium">
                    Ja. Selbst wenn dein Mobilgerät nicht mit dem WLAN verbunden ist,
                    kannst du die Sonos App nutzen, um den Sonos Ace
                    zu registrieren und bestimmte Features zu steuern.
                </P>
            </Accordion>
        </motion.div>
        <motion.div
            initial="outOfView"
            variants={FadeInAnimation}
            viewport={{
                amount: 0.4,
                once: true
            }}
            whileInView="inView"
        >
            <Accordion title="Benötigt der Sonos Ace WLAN?">
                <P $size="medium">
                    Nein. Der Sonos Ace nutzt Bluetooth, um sich mit Geräten zu verbinden.
                </P>
            </Accordion>
        </motion.div>
        <motion.div
            initial="outOfView"
            variants={FadeInAnimation}
            viewport={{
                amount: 0.4,
                once: true
            }}
            whileInView="inView"
        >
            <Accordion title="Kann ich für den Sonos Ace ein AUX-Kabel nutzen?">
                <P $size="medium">
                    Ja. Verbinde deine bevorzugte analoge Quelle mit dem im Lieferumfang enthalten
                    USB-C-zu-3,5-mm-Kabel. Du kannst auch das mitgelieferte USB-C-zu-USB-C-Kabel nutzen,
                    um eine kabelgebundene Verbindung herzustellen.
                </P>
            </Accordion>
        </motion.div>
        <motion.div
            initial="outOfView"
            variants={FadeInAnimation}
            viewport={{
                amount: 0.4,
                once: true
            }}
            whileInView="inView"
        >
            <Accordion title="Welche Soundbars sind mit dem Sonos Ace kompatibel?">
                <P $size="medium">
                    Der Sonos Ace ist kompatibel mit der Arc.<br />
                    Auf{' '}
                    <InlineTextLink
                        href="https://faq.sonos.com/sonos-ace/tv-swap"
                        rel="nofollow noindex noopener noreferrer"
                        target="_blank"
                    >
                        faq.sonos.com/sonos-ace/tv-swap
                    </InlineTextLink>{' '}
                    erhältst du weitere Informationen.
                </P>
            </Accordion>
        </motion.div>
        <motion.div
            initial="outOfView"
            variants={FadeInAnimation}
            viewport={{
                amount: 0.4,
                once: true
            }}
            whileInView="inView"
        >
            <Accordion title="Verbindet sich der Sonos Ace mit meinem Sonos System?">
                <P $size="medium">
                    Der Sonos Ace verbindet sich auf eine andere Art und Weise als deine Sonos Speaker.
                    Du kannst TV Sound zwischen dem Sonos Ace und ausgewählten Soundbars switchen.{' '}
                    <InlineTextLink
                        href="https://faq.sonos.com/spatial-home-theater"
                        rel="nofollow noindex noopener noreferrer"
                        target="_blank"
                    >
                        Hier
                    </InlineTextLink>{' '}erfährst du mehr über das Heimkino-Erlebnis mit dem Sonos Ace.
                </P>
            </Accordion>
        </motion.div>
        <motion.div
            initial="outOfView"
            variants={FadeInAnimation}
            viewport={{
                amount: 0.4,
                once: true
            }}
            whileInView="inView"
        >
            <Accordion title="Ist der Sonos Ace wasserdicht?">
                <P $size="medium">
                    Der Sonos Ace ist nicht wasserdicht und er verfügt über keine offizielle Schutzart.
                </P>
            </Accordion>
        </motion.div>
    </StyledContainer>
);

Faq.displayName = 'Faq';
Faq.defaultProps = {testId: 'Faq'};

export {Faq};

interface StyledContainerProps {
    id: string;
}

const StyledContainer = styled(Container)<StyledContainerProps>`
    padding-block: ${spacing(14)};

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;