import {media, Spacer, useScreenSize} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {H6} from 'UI/components/layout/Text';

import {
    AccordionContentTransition,
    AccordionHeaderTransition,
    PlusAnimation
} from 'UI/animations/accordion';

import {useAccordion} from './useAccordion';
import {Plus} from 'Images/icons';

import type {WithChildren} from 'types/global';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
    /**
     * The title of the accordion. This is displayed at the top of the accordion and is always visible.
     */
    title: string;
}

/**
 * Renders an accordion component with customizable content, title, and an animated icon that indicates open or close state.
 * This component utilizes a custom hook `useAccordion` to manage its open/close state and `useScreenSize` to adapt the icon size
 * according to the screen size. The `Accordion` component is flexible, allowing any React node to be passed as children. It is styled
 * and animated with Framer Motion for smooth transitions. The component's appearance can be further customized via `ComponentProps`.
 *
 * @param props          The component props.
 * @param props.children The content to be displayed within the accordion. This can be any React node (e.g., text, components).
 * @param props.testId   A unique identifier for the component, useful for testing purposes.
 * @param props.title    The title of the accordion. This is displayed at the top of the accordion and is always visible.
 * @returns A React component that displays a customizable accordion with animation effects.
 *
 * @example
 * ```tsx
 * <Accordion title="FAQ" testId="faq-accordion">
 *   <p>This is an example of content inside an accordion.</p>
 * </Accordion>
 * ```
 */
const Accordion = ({children, testId, title}: WithChildren<ComponentProps>) => {
    const {handleClick, isOpen} = useAccordion();
    const breakpoint = useScreenSize();

    // eslint-disable-next-line @nfq/no-magic-numbers
    const iconSize = ['xs', 'sm'].includes(breakpoint) ? 32 : 40;

    return (
        <Wrapper
            animate={isOpen ? 'open' : 'close'}
            data-cy={testId}
            initial="close"
            variants={AccordionHeaderTransition}
        >
            <Spacer y={{md: 16, xs: 10}} />
            <AccordionHeader onClick={handleClick}>
                <StyledH6 $align="left" as="p">
                    {title}
                </StyledH6>
                <Spacer x={6} />
                <IconWrapper variants={PlusAnimation}>
                    <Plus height={iconSize} width={iconSize} />
                </IconWrapper>
            </AccordionHeader>
            <Spacer y={{md: 8, xs: 4}} />
            <AccordionContent variants={AccordionContentTransition}>
                {children}
                <Spacer y={{md: 8, xs: 4}} />
            </AccordionContent>
            <Spacer y={{md: 8, xs: 6}} />
        </Wrapper>
    );
};

Accordion.displayName = 'Accordion';
Accordion.defaultProps = {testId: 'Accordion'};

export {Accordion};

const Wrapper = styled(motion.div)`
    border-bottom: 2px solid ${({theme}) => theme.colors.primaryFontColor};
    overflow: hidden;
`;

const AccordionHeader = styled.button`
    align-items: flex-start;
    background: none;
    border: none;
    color: ${({theme}) => theme.colors.primaryFontColor};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0;
    user-select: none;
    width: 100%;


    &:focus-visible {
        color: ${({theme}) => theme.colors.focusColor};
        outline: none;
    }
`;

const AccordionContent = styled(motion.div)`
    max-width: calc(100% - 5.6rem);

    ${media('md')} {
        max-width: 70%;
    }
`;

const IconWrapper = styled(motion.div)`
    display: inline-block;
    line-height: 0;
`;

const StyledH6 = styled(H6)`
    ${media('md')} {
        max-width: 70%;
    }
`;