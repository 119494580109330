import {media, Spacer} from '@nfq/react-grid';
import styled from 'styled-components';

import {GridItem} from 'UI/components/layout/GridItem';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Displays detailed information about the energy characteristics of a device within an animated container.
 * This component includes data about charging options, Bluetooth capabilities, battery life, operating temperature, and voice control functionalities.
 * It's structured to provide clear and essential energy-related information to users, helping them understand the device's capabilities and requirements.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier used for testing purposes, ensuring the component can be specifically targeted in tests.
 * @returns A JSX.Element that organizes energy-related information into a visually appealing grid with animation effects as items come into view.
 *
 * @example
 * ```tsx
 * <Energy testId="energy-details-1" />
 * ```
 */
const Energy = ({testId}: ComponentProps) => (
    <InViewAnimContainer baseComponent={Wrapper} data-cy={testId} stagger={0.1}>
        <GridItem label="Aufladen" testId="aufladen-item">
            Mithilfe eines USB-C-Kabels und eines kompatiblen Netzteils aufladen.
            <Spacer y={4} />
            Schnelles Aufladen ab 0 % Akkuladung ermöglicht 3 Stunden Hör- oder Sprechzeit innerhalb von 3 Minuten.
            <Spacer y={4} />
            Bis zu 3 Stunden Ladezeit bei 0 % Akkuladung für ein vollständiges Aufladen.
        </GridItem>
        <GridItem label="Bluetooth" testId="bluetooth-item">
            Bluetooth 5.2 unterstützt Audio Streaming von jedem Bluetooth-fähigen Gerät.
        </GridItem>
        <GridItem label="Akku" testId="akku-item">
            Lithium-Ionen-Batterie mit 1060 mAh für mindestens 30 Stunden Hör- oder 24 Stunden Sprechzeit mit
            aktivierter Geräuschunterdrückung oder aktiviertem Aware Modus.
        </GridItem>
        <GridItem label="Betriebstemperatur" testId="betriebstemperatur-item">
            -10 °C bis 40 °C
        </GridItem>
        <GridItem label="Sprachsteuerung" testId="sprachsteuerung-item">
            Sonos Voice Control (in der Sonos App aktivieren)
        </GridItem>
    </InViewAnimContainer>
);

Energy.hash = '#energy';
Energy.displayName = 'Energy';
Energy.defaultProps = {testId: 'Energy'};

export {Energy};

const Wrapper = styled.div`
    display: grid;
    grid-gap: 5.6rem;
    grid-template-columns: 1fr;
    width: 100%;

    ${media('sm')} {
        grid-template-columns: 1fr 1fr;
    }

    ${media('lg')} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;