/* eslint-disable import/max-dependencies, react/jsx-props-no-spreading */
import {Spacer} from '@nfq/react-grid';
import dynamic from 'next/dynamic';

import {Section} from 'UI/components/layout/Section';

import {SonosLayout} from 'UI/layouts/SonosLayout';
import {Faq} from 'UI/modules/Faq';
import {Footnotes} from 'UI/modules/Footnotes';
import {FullsizeVideo} from 'UI/modules/FullsizeVideo';
import {Hero} from 'UI/modules/Hero/Hero';
import {LogoCarousel} from 'UI/modules/LogoCarousel';
import {StoresDistractor} from 'UI/modules/StoresDistractor';
import {TechnicalDetails} from 'UI/modules/TechnicalDetails';

import type {NextPageWithLayout} from 'types/global';

export const UnderTheFold = dynamic(async () => {
    const module = await import(
        /* webpackChunkName: "HitDifferent" */
        'UI/modules/UnderTheFold'
    );

    return module.UnderTheFold;
});

/**
 * The `HomePage` component is a Next.js page component.
 * It is used as a route and can be accessed throught the nextjs router.
 *
 * @returns A ReactNode representing the `HomePage` page.
 */
const HomePage: NextPageWithLayout = () => (
    <>
        <Section testId="IntroSection" hasNoPaddingBottom hasNoPaddingTop>
            <Hero testId="IntroSection" />
        </Section>
        <StoresDistractor />
        <FullsizeVideo testId="fullsizeVideoSection" />
        <UnderTheFold />
        <StoresDistractor />
        <Section testId="technicalDestailsSection">
            <TechnicalDetails testId="technicalDetails" />
        </Section>
        <Section bgColor="whiteBoxBg" testId="faqSection">
            <Faq testId="faq" />
        </Section>
        <Section testId="homePageSection" hasNoPaddingBottom>
            <Footnotes testId="Footnotes" />
            <Spacer y={{md: 16, xs: 14}} isNotStretching />
            <LogoCarousel testId="LogoCarousel" />
            <Spacer y={{md: 16, xs: 14}} isNotStretching />

        </Section>
    </>
);

/**
 * `getLayout` is a static property of the `HomePage` component that is a function designed to wrap the component with a specified layout.
 * It receives the router object, page properties, and the PageComponent and returns the PageComponent wrapped with the necessary layout and properties.
 * This property is essential for applying consistent layouts across different pages in the application.
 *
 * @param router        The Next.js router object representing the current route of the application.
 * @param pageProps     The page properties object representing the properties of the current page.
 * @param PageComponent The PageComponent representing the current page.
 * @returns A ReactNode representing the PageComponent wrapped with the necessary layout and properties.
 *
 * @example
 * ```tsx
 * const LayoutWrappedComponent = HomePage.getLayout(router, pageProps, PageComponent);
 * ```
 */
HomePage.getLayout = (router, pageProps, PageComponent) => (
    <SonosLayout testId="homePageLayout" title="Sonos Ace | Jetzt im Handel erhältlich" isHome>
        <PageComponent router={router} {...pageProps} />
    </SonosLayout>
);

/**
 * `getLayoutKey` is a static property of the `HomePage` component that is a function designed to return the layout key string.
 * It is used to determine if the layout changed between pages to apply transitions between different layouts.
 * This property is crucial for managing different layouts within the application and for providing a specific layout key for the `HomePage` component.
 *
 * @returns A string representing the layout key.
 *
 * @example
 * ```tsx
 * const layoutKey = HomePage.getLayoutKey();
 * ```
 */
HomePage.getLayoutKey = () => '';

export default HomePage;