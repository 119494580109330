import {Col, Container, media, Row, spacing, useScreenSize} from '@nfq/react-grid';
import styled from 'styled-components';

import {Button} from 'UI/components/action/Button';
import {Color, P} from 'UI/components/layout/Text';

import {useThemeColors} from 'UI/hooks/useThemeColors';

import {Pointer} from 'Images/icons';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a call-to-action (CTA) component encouraging users to visit local partner stores. The `StoresDistractor` component
 * is designed to guide users interested in experiencing products in person to find a nearby store. It combines a compelling
 * message with a button that links to the store finder page. This component adapts to different screen sizes, ensuring the
 * layout and presentation are optimized for the best user experience. The message is highlighted in a specific font color to
 * attract attention, and a flexible pointer icon adds visual interest to the CTA. This component effectively bridges online
 * information with offline exploration, enhancing the overall customer journey by providing options for physical engagement
 * with the products.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for testing purposes, enabling precise targeting of the component in tests.
 * @returns A JSX.Element that renders a CTA for users to discover local partner stores, enhancing the connection between online content and offline experiences.
 *
 * @example
 * ```tsx
 * <StoresDistractor testId="store-distractor-section" />
 * ```
 */
const StoresDistractor = ({testId}: ComponentProps) => {
    const colors = useThemeColors();
    const screenSize = useScreenSize();

    return (
        <Wrapper data-cy={testId}>
            <Container>
                <Row hasNoWrap={['xxl', 'xl', 'lg', 'md']} justify="space-between">
                    <TextCol align="center" direction="row">
                        <TextWrapper>
                            <FlexPointer />
                            <P $align="center">
                                <Color $color={colors.primaryFontColorLight}>
                                    Lieber vor Ort ausprobieren? Entdecke deinen nächstgelegenen Partner Store!
                                </Color>
                            </P>
                        </TextWrapper>
                    </TextCol>
                    <Col md="max-content" xs={12}>
                        <Button
                            as="link"
                            href="/kaufen#store-finder"
                            scroll={false}
                            variant="outlined"
                            width={['xs', 'sm'].includes(screenSize) ? 'full' : 'auto'}
                            isInverted
                        >
                            Store Finder
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

StoresDistractor.displayName = 'StoresDistractor';
StoresDistractor.defaultProps = {testId: 'StoresDistractor'};

export {StoresDistractor};

const Wrapper = styled.section`
    background-color: ${({theme}) => theme.colors.blackBoxBg};
    padding-block: ${spacing(14)};
    width: 100%;

    ${media('md')} {
        padding-block: ${spacing(11)};
    }
`;

const TextCol = styled(Col)`
    min-width: 0;
`;

const TextWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    ${media('sm')} {
        flex-direction: row;
    }
`;

const FlexPointer = styled(Pointer)`
    flex: 0 0 4rem;
`;