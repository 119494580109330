/* eslint-disable max-len */
import React from 'react';

import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {PlusMinusTransition} from 'UI/animations/accordion';

interface PlusProps {
    /**
     * A string representing the CSS class to be applied to the PlusIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the PlusIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the PlusIcon element.
     */
    width?: number | string;
}

/**
 * The `Plus` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `PlusProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the PlusIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the PlusIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the PlusIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const PlusComponent = <Plus className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Plus = React.forwardRef<SVGSVGElement, PlusProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 41 41"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color1}>
                <PlusPath
                    clipRule="evenodd"
                    d="M20 40.7734L20 0.773437L22 0.773438L22 40.7734L20 40.7734Z"
                    fillRule="evenodd"
                    variants={PlusMinusTransition}
                />
                <path
                    clipRule="evenodd"
                    d="M0.890625 20.7734L40.8906 20.7734L40.8906 22.7734L0.890625 22.7734L0.890625 20.7734Z"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    );
});

Plus.displayName = 'Plus';
Plus.defaultProps = {
    className: '',
    color1: '#212325',
    height: 40,
    testId: 'Plus',
    width: 40
};

const PlusPath = styled(motion.path)``;